import React from "react";
import { render } from "react-dom";
import Select from 'react-select';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  useMutation,
  gql
} from "@apollo/client";

const client = new ApolloClient({
  uri: "https://strapi.engles.fr/graphql",
  cache: new InMemoryCache()
});

const GET_CONFIG = gql`
  {
    chauffeEauRocherol {
      id
      start
      end
      mode
    }
  }
`;
const UPDATE = gql`
  mutation UpdateChauffeEauRocherol( $start: Time!, $end: Time!, $mode: ENUM_CHAUFFEEAUROCHEROLS_MODE!) {
    updateChauffeEauRocherol(
    input: {
	  data: {  start: $start , end: $end, mode: $mode}
	      }
	      ) {
    chauffeEauRocherol {
      id
      start
      end
      mode
      }
    }
  }
`;

/*function handleChanges(event){
    var inputMode;
	//alert(event.value);
	inputMode = event.value;

}*/

function Configs() {



  const { loading, error, data } = useQuery(GET_CONFIG);
  const [
    updateValues,
    { loading: mutationLoading, error: mutationError }
  ] = useMutation(UPDATE);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

    let input1;
    let input2;
    var inputMode;

	  let start = data.chauffeEauRocherol.start;
	  let end = data.chauffeEauRocherol.end;
	  let mode = data.chauffeEauRocherol.mode;
	  let id = data.chauffeEauRocherol.id;

  var selectedValue = data.chauffeEauRocherol.mode;
 
  // handle onChange event of the dropdown
  const handleChanges = e => {
    selectedValue = e.value;
  }

	let colourOptions = [
		{value: 'normal', label: 'Normal'},
		{value: 'on', label: 'ON'},
		{value: 'off', label: 'OFF'},
	]
		//value={colourOptions.find(obj => obj.value === selectedValue)}
    return (
      <div key={id}>
        <form
          onSubmit={e => {
            e.preventDefault();
            updateValues({ variables: {  start: input1.value + ":00.000", end: input2.value + ":00.000", mode: selectedValue} });

    input1.value = input1.value && input1.value || start;
    input2.value = input2.value && input2.value || end;
          }}
        >
	    <Select
		value={selectedValue}
	       onChange={handleChanges}
	       options={colourOptions}
	       placeholder="Mode"
	    />
          <input
            ref={node => {
              input1 = node;
            }}
	    defaultValue={start.split(":")[0]+":"+start.split(":")[1]}
	    type="time"
          />
          <input
            ref={node => {
              input2 = node;
            }}
	    defaultValue={end.split(":")[0]+":"+end.split(":")[1]}
	    type="time"
          />
          <button type="submit">Update</button>
        </form>
        {mutationLoading && <p>Loading...</p>}
        {mutationError && <p>Error :( Please try again</p>}
        <p>Valeurs enregistrées: mode {mode} {start} - {end}</p>
      </div>
    );
}
function App() {
  return (
    <ApolloProvider client={client}>
      <div>
        <h2>Chauffe eau Rocherols</h2>
        <Configs />
      </div>
    </ApolloProvider>
  );
}

const Index = () => {
	return App();
}

export default Index;
